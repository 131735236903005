<template>
    <div>
      <s-toolbar label="Notas Provicionales" />
      <v-card>
        <v-card-text>
          <v-row>
            <v-col lg="12">
              <v-label> Carga Masiva de Notas Provicionales </v-label>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="5" md="5">
              <s-import-excel
                label="Leer archivo excel"
                @clear="itemsProvisionalNotes = []"
                @changeData="changeFile($event)"
              ></s-import-excel>
            </v-col>
            <!-- <v-col cols="5" md="5" class="s-col-form">
              <s-load
                @clear="clear()"
                label="Adjunte archivo pdf"
                v-model="files"
              />
            </v-col> -->
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="12" md="12">
              <v-data-table
                dense
                :single-select="true"
                :headers="headers"
                @click:row="rowClick"
                :items="itemsProvisionalNotes"
                locale="es-ES"
                selectable-key="isSelectable"
                :items-per-page="10"
                item-key="ID"
                ref="stable"
                v-model="selected"
                :footer-props="{
                  showCurrentPage: true,
                  showPageText: true,
                  showFirstLastPage: false,
                  showPerPageOptions: true,
                  showPerPageText: true,
                  itemsPerPageAllText: 'Todos',
                  noResultsText: 'sin resultados',
                  noDataText: 'sin resultados',
                  itemsPerPageOptions: [10, 25, 50, -1],
                  itemsPerPageText: 'Registros',
                }"
              >
                <template slot="no-data"> Sin Datos </template>
                <template slot="no-results"> Sin Datos </template>
                <template slot:footer.page-text="{ pageStop, itemsLength }">
                  {{ itemsLength }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-col class="s-col-form" @click="cancel()"
            ><v-btn block text>Cancelar</v-btn></v-col
          >
          <v-col class="s-col-form"
            ><v-btn block color="primary" @click="save()">
              <v-icon small left>fas fa-save</v-icon>Guardar</v-btn
            ></v-col
          >
        </v-card-actions>
      </v-card>
    </div>
  </template>
  
  <script>
  import _ProvisionalNotes from "@/services/ExternalTrade/ExtProvisionalNotesService";
    
  import _sHelper from "@/services/HelperService";
  
  export default {
    components: {},
    props: {
      FechaVencimiento: { type: Date },
    },
    data: () => ({
      itemsProvisionalNotes: [],
      selected: [],
      files: [],
      config: {
        model: {
          FechaContabilizacion: "date",
        },
      },
      headers: [
        { text: "Numero Factura", value: "NumInvoice", sortable: false },
        { text: "Monto", value: "Amount",  sortable: false },
        { text: "Moneda", value: "Currency",  sortable: false },
        { text: "Tipo Nota", value: "TypeDocument", sortable: false },
        { text: "Nota Crédito Provicional", value: "NumProvisionalNotes", sortable: false },
        //{ text: "Número del documento", value: "NumDocument", sortable: false },
        { text: "Fecha Documento", value: "DateDocument", sortable: false },
      ],
      itemsLength: 0,
      OptFile: "",
    }),
    watch: {
      files() {
        if (this.files) this.changeData(this.files);
      },
    },
    methods: {
      rowClick: function (item, row) {
        this.selected = [item];
        this.row = Object.assign({}, item);
        this.$emit("rowSelected", this.selected);
      },
      changeFile(data) {
        let item = {};
        var i = 0;
        console.log(data);
        data.forEach((element) => {
          item = {
            DateDocument: 
              data[i]["Fecha documento"]
            ,
            NumInvoice: data[i]["Número Factura"],
            Amount: data[i]["Monto"],
            Currency: data[i]["Moneda"],
            TypeDocument: data[i]["Tipo Nota"],
            NumProvisionalNotes: data[i]["Nota Crédito Provicional"],
            NumDocument: data[i]["Número del documento"],
             
          };
          i++;
          item.ID = this.itemsProvisionalNotes.length + 1;
          this.itemsProvisionalNotes.push(item);
        });
      },
      save() {
        if (this.itemsProvisionalNotes.length > 0) {
          if (this.files != null) {
            this.$fun.alert("Seguro de Guardar", "question").then((r) => {
              if (r.value) {
                var fileName = this.files.name;
                 
                var formData = new FormData();
                formData.append("file", this.files);
  
                //aqui se subi el archivo si fuera necesario sino quitar
                _sHelper
                  .uploadfile(1, this.$fun.getUserID(), formData, 3)
                  .then((respose) => {
                    if (respose.status == 200) {
                        _ProvisionalNotes
                        .save(this.itemsProvisionalNotes, this.$fun.getUserID())
                        .then((resp) => {
                          if (resp.status == 200) {
     
                            this.itemsProvisionalNotes = [];
                              this.$fun.alert(
                                "Agregados Correctamente "  ,
                                   
                                "success"
                              );
                             
                          }
                        });
                    }
                  });
              }
            });
          } else {
            this.$fun.alert("Anexe Archivo de Notas Provicionales", "warning");
          }
        } else {
          this.$fun.alert("Sin datos que cargar", "warning");
        }
      },
      changeData(items) {
        if (this.txtFile(items.name) == "pdf") {
          return true;
        } else {
          this.files = [];
          this.$fun.alert("Formato No permitido", "error");
          return false;
        }
      },
  
      txtFile(file) {
        return file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
      },
      clear() {},
      cancel() {
        this.itemsDetraction = [];
      },
    },
  };
  </script>
  